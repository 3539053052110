import styled, { css } from 'styled-components';
import { heading3 } from 'Global/Typography';

import Link from 'Elements/Link/Link';

export const Heading = styled.h2<{ $contactInformation?: boolean }>`
    ${({ theme, $contactInformation }) => css`
        ${heading3}
        font-size: 1.25rem;
        margin-top: 0;
        /*if first contact info undefined/false dont apply margin style*/
        ${!$contactInformation &&
        css`
            margin: 0;
        `}

        @media ${theme.breakpoints.large.media} {
            padding-bottom: 0.5rem;
            font-size: 1.5rem;
        }
    `}
`;

export const Details = styled.div`
    margin-top: 2rem;

    &:first-of-type {
        margin-top: 0rem;
    }
`;

export const Information = styled.p`
    margin: 0;
    font-weight: 700;
`;

export const Contact = styled.span`
    display: flex;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    @media ${({ theme }) => theme.breakpoints.large.media} {
        margin-top: 0.75rem;
    }
`;

export const StyledLink = styled(Link)`
    font-weight: 400;
`;

export const Icon = styled.svg`
    ${({ theme }) => css`
        height: 1rem;
        width: 1rem;
        color: ${theme.colors.primary.hex};
        stroke-width: 0.15rem;
        margin: 0.25rem 0.4rem 0 0;
    `}
`;

export const List = styled.ul`
    margin: 0;
    padding: 0;
`;

export const ListItem = styled.li`
    list-style: none;
    margin-left: 0.25rem;

    &:first-of-type {
        margin-top: 0;
    }
`;
