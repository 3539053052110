import React from 'react';

import UniBuddy, { UniBuddyPopCardProps } from 'Common/util/uniBuddy';

import Main from 'Elements/Main';
import { H2, H3 } from 'Elements/Typography';
import FlexBox from 'Elements/FlexBox';

import Grid, { Col } from 'Components/Grid';
import Breadcrumbs, { BreadcrumbsProps } from 'Components/Breadcrumbs';
import ImageAndText from 'Components/TextBlock/ImageAndText';
import ImageCaption from 'Components/ImageCaption';
import Video from 'Components/Video';
import AttentionBox from 'Components/AttentionBox';
import Accordion from 'Components/Accordion';
import List from 'Components/List';
import Profile from 'Components/Card/Profile';
import Unibuddy from 'Components/Unibuddy';
import UnibuddyCarousel from 'Components/Unibuddy/Carousel';
import IframeGecko from 'Components/IframeGecko';
import ContactInfo from 'Components/SupportBlock/ContactInfo';

import MakeWavesHeroBanner, { MakeWavesHeroBannerProps } from 'Modules/HeroBanner/MakeWaves';
import SectionNavigation, { SectionNavigationProps } from 'Modules/SectionNavigation';
import InPageNavigation, { InPageNavigationProps } from 'Modules/InPageNavigation';
import LinkCards from 'Modules/Cards/Link';
import MiniCards from 'Modules/Cards/Mini';
import BrandBanner from 'Modules/Banner/Brand';
import TefAward from 'Modules/PromoPoint/TefAward';

import { ContentItem, AnchorContainer, Anchor } from '../Content.styled';
import { FlexibleContentCanvasProps, MappedBlock, isJSXElement } from 'Common/util/cmsCanvas';
import { defaultSeparateBlockTypes } from 'Common/util/cmsCanvas/CanvasDataMapper';

export interface Props {
    breadcrumbs: BreadcrumbsProps;
    heroBanner: MakeWavesHeroBannerProps;
    sectionNavigation: SectionNavigationProps;
    inPageNavigation: InPageNavigationProps;
    content: MappedBlock<FlexibleContentCanvasProps>[];
    uniBuddyPopcard: { hide: boolean } & UniBuddyPopCardProps;
}

/**
 * Add names of composer blocks here where the line breaks at the bottom of the page are not required.
 * These blocks would typically be the ones that have their own background colour
 **/
const marginBottomRuleset = ['brandBanner', 'geckoForm', 'unibuddyMain', 'unibuddyCarousel'];

const Content: React.FC<Props> = props => {
    const {
        breadcrumbs,
        heroBanner,
        sectionNavigation,
        inPageNavigation,
        content,
        uniBuddyPopcard
    } = props;

    const renderContent = (
        item: MappedBlock<FlexibleContentCanvasProps>
    ): JSX.Element | undefined => {
        if (item.groupName === '_defaultGroup' && isJSXElement(item.elements)) {
            return (
                <ComposerItemWrapper>
                    {item.elements.map(element => (
                        <React.Fragment key={element.key}>{element}</React.Fragment>
                    ))}
                </ComposerItemWrapper>
            );
        }

        if (defaultSeparateBlockTypes.includes(item.groupName) && isJSXElement(item.elements)) {
            const component = item.elements;
            return (
                <>
                    {item.groupName === '_table' && (
                        <ComposerItemWrapper columnStart={1} columnEnd={13}>
                            {component[0]}
                        </ComposerItemWrapper>
                    )}
                    {item.groupName === '_quote' && (
                        <ComposerItemWrapper>{component[0]}</ComposerItemWrapper>
                    )}
                    {item.groupName === '_divider' && component[0]}
                </>
            );
        }

        if (!isJSXElement(item.elements)) {
            const component = item.elements;
            return (
                <>
                    {component.textImageTwoColumns && (
                        <ComposerItemWrapper>
                            <ImageAndText {...component.textImageTwoColumns} />
                        </ComposerItemWrapper>
                    )}
                    {component.image && (
                        <ComposerItemWrapper
                            columnStart={7 - component.image.columnWidth / 2}
                            columnEnd={7 + component.image.columnWidth / 2}
                        >
                            <ImageCaption {...component.image.imageCaption} />
                        </ComposerItemWrapper>
                    )}
                    {component.attentionBox && (
                        <ComposerItemWrapper columnStart={4} columnEnd={10}>
                            <AttentionBox {...component.attentionBox} />
                        </ComposerItemWrapper>
                    )}
                    {component.accordionCollectionCanvas && (
                        <ComposerItemWrapper>
                            {component.accordionCollectionCanvas.map(
                                ({ children, ...rest }, index) => (
                                    <Accordion {...rest} defaultOpen={index === 0} key={rest.id}>
                                        {children}
                                    </Accordion>
                                )
                            )}
                        </ComposerItemWrapper>
                    )}
                    {component.video && (
                        <ComposerItemWrapper>
                            {component.video.heading && <h2>{component.video.heading}</h2>}
                            <Video {...component.video.embed} />
                        </ComposerItemWrapper>
                    )}
                    {component.listTwoColumns && (
                        <ComposerItemWrapper>
                            <Grid>
                                <Col l={6}>
                                    {component.listTwoColumns.listOneHeading && (
                                        <H2 spacing={{ base: { margin: 0 } }}>
                                            {component.listTwoColumns.listOneHeading}
                                        </H2>
                                    )}
                                    <List {...component.listTwoColumns.listOne} />
                                </Col>
                                <Col l={6}>
                                    {component.listTwoColumns.listTwoHeading && (
                                        <H2 spacing={{ base: { margin: 0 } }}>
                                            {component.listTwoColumns.listTwoHeading}
                                        </H2>
                                    )}
                                    <List {...component.listTwoColumns.listTwo} />
                                </Col>
                            </Grid>
                        </ComposerItemWrapper>
                    )}
                    {component.promoCards && (
                        <ComposerItemWrapper columnStart={1} columnEnd={13}>
                            <LinkCards {...component.promoCards} />
                        </ComposerItemWrapper>
                    )}
                    {component.meetTheTeam && (
                        <ComposerItemWrapper columnStart={1} columnEnd={13}>
                            {component.meetTheTeam.heading && (
                                <Grid>
                                    <Col>
                                        <H2>{component.meetTheTeam.heading}</H2>
                                    </Col>
                                </Grid>
                            )}
                            <Grid>
                                <Col>
                                    <MiniCards {...component.meetTheTeam.cards} />
                                </Col>
                            </Grid>
                        </ComposerItemWrapper>
                    )}
                    {component.brandBanner && (
                        <ContentItem>
                            <BrandBanner {...component.brandBanner} />
                        </ContentItem>
                    )}
                    {component.tefAward && (
                        <ContentItem>
                            <TefAward {...component.tefAward} />
                        </ContentItem>
                    )}
                    {component.flexibleAcademicStaffProfile && (
                        <Grid>
                            <Col m={[0, 7]} l={[4, 10]}>
                                <ContentItem>
                                    {component.flexibleAcademicStaffProfile.heading && (
                                        <H3
                                            size={{ base: '1.125rem', large: '1.5rem' }}
                                            spacing={{
                                                base: { margin: '0 0 1rem 0' },
                                                medium: { margin: '0 0 2rem 0' }
                                            }}
                                        >
                                            {component.flexibleAcademicStaffProfile.heading}
                                        </H3>
                                    )}
                                    <Profile {...component.flexibleAcademicStaffProfile} border />
                                </ContentItem>
                            </Col>
                        </Grid>
                    )}
                    {component.geckoForm && (
                        <ContentItem>
                            <IframeGecko {...component.geckoForm} />
                        </ContentItem>
                    )}
                    {component.contactInformation && (
                        <Grid>
                            <Col l={[3, 11]}>
                                <ContentItem>
                                    {component.contactInformation.heading && (
                                        <h2>{component.contactInformation.heading}</h2>
                                    )}
                                    <FlexBox cols={{ base: 1, medium: 2 }} gap="1.5rem" wrap="wrap">
                                        {component.contactInformation.contacts.map(
                                            (contact, index) => (
                                                <ContactInfo key={index} {...contact} />
                                            )
                                        )}
                                    </FlexBox>
                                </ContentItem>
                            </Col>
                        </Grid>
                    )}
                    {component.unibuddyMain && (
                        <Unibuddy {...component.unibuddyMain} columnStart={3} columnEnd={11} />
                    )}
                    {component.unibuddyCarousel && (
                        <UnibuddyCarousel
                            {...component.unibuddyCarousel}
                            columnStart={3}
                            columnEnd={11}
                        />
                    )}
                    {component.anchor && (
                        <Col l={[3, 11]}>
                            <AnchorContainer>
                                <Anchor id={component.anchor} />
                            </AnchorContainer>
                        </Col>
                    )}
                </>
            );
        }
    };

    return (
        <>
            <Breadcrumbs {...breadcrumbs} />
            <MakeWavesHeroBanner {...heroBanner} />
            <Main>
                <SectionNavigation {...sectionNavigation} />
                {inPageNavigation.links.length > 0 && (
                    <Grid>
                        <Col l={[3, 11]}>
                            <InPageNavigation {...inPageNavigation} />
                        </Col>
                    </Grid>
                )}
                {content.map((item, index) => (
                    <React.Fragment key={index}>{renderContent(item)}</React.Fragment>
                ))}
                {content.length > 0 &&
                    !marginBottomRuleset.includes(
                        Object.keys(content[content.length - 1].elements)[0]
                    ) && (
                        <>
                            <br />
                            <br />
                        </>
                    )}
                {!uniBuddyPopcard.hide &&
                    UniBuddy({
                        filterValue: uniBuddyPopcard.filterValue
                            ? uniBuddyPopcard.filterValue
                            : 'All'
                    })}
            </Main>
        </>
    );
};

const ComposerItemWrapper: React.FC<{
    columnStart?: number;
    columnEnd?: number;
}> = props => {
    const { children, columnStart = 3, columnEnd = 11 } = props;

    return (
        <Grid>
            <Col l={[columnStart, columnEnd]}>
                <ContentItem>{children}</ContentItem>
            </Col>
        </Grid>
    );
};

export default Content;
